import { BrowserRouter, Routes, Route, Outlet, useLocation } from "react-router-dom";
import { Playground } from "./playground";
import ClearAlertsOnRouteChange from "@sprint1/pkg/src/router/clearAlertsOnRouteChange";
import { DefaultUnhandledRejectionHandler } from "@sprint1/pkg/src/unhandledRejection/DefaultUnhandledRejectionHandler";
import { UserContainer, UserContextType, useUser } from "@sprint1/pkg/src/auth/user/context";
import { useRunOnMount } from "@sprint1/pkg/src/useRunOnMount/useRunOnMount";
import { useState } from "react";
import { ToastContainer } from "@sprint1/pkg/src/toast/container";
import { LangContainer } from "@sprint1/pkg/src/i18n/langContainer";

import { AppHeader } from "components/header";
import NotFound from "@sprint1/pkg/src/404/NotFound";
import { Messages } from "./messages";
import { Dashboard } from "./patient/Dashboard";
import { MedicalEmergency } from "./patient/MedicalEmergency";
import { WorkersCompDisclaimer } from "./patient/WorkersCompDisclaimer";
import { MedicalHistory } from "./patient/medicalHistory";
import { Questionnaire } from "./patient/questionnaire";
import { Home } from "./Home";
import { ClientsList } from "./admin/clients/List";
import { EditClient } from "./admin/clients/Edit";
import { routes } from "./routes.config";
import { EditUser } from "./admin/users/Edit";
import { UsersList } from "./admin/users/List";
import { ScheduleAppointment } from "./patient/ScheduleAppointment";
import { ScheduleAppointment as CgScheduleAppointment } from "./cg/ScheduleAppointment";
import { RolesEnum } from "common/roles";
import { SelectBodyPart } from "./patient/SelectBodyPart";
import { WorkersCompList } from "./cg/WorkersCompList";
import { Schedule } from "./cg/schedule";
import { PatientList } from "./cg/PatientList";
import { Orders } from "./cg/Orders";
import { PatientDetails } from "./cg/patientDetails";
import en from "locale/en/translation.json";
import es from "locale/es/translation.json";
import { Results } from "./cg/Results";
import { VisitSummary } from "./patient/VisitSummary";
import { Settings } from "./cg/Settings";
import { PrintOrders } from "./print/orders";
import { OrthoLibrary } from "./library/OrthoLibrary";
import { PhysicalTherapy } from "./library/PhysicalTherapy";
import { SessionGuard } from "@sprint1/pkg/src/sessionGuard";
import { tryGetLoggedInUser } from "api/client/user/tryGetLoggedInUser";
import { User } from "@sprint1/pkg/src/api/auth/types/user";
import { EditAssociatedUser } from "./admin/users/EditAssociatedUser";
import { PrintEncounter } from "./cg/patientDetails/encounterDetails/print";
import { Landing } from "./patient/selfpay/Landing";
import { SignUp } from "./patient/selfpay/Signup";
import { Pay } from "./patient/selfpay/Pay";
import { PaymentSuccess } from "./patient/selfpay/PaymentSuccess";
import { SignUpComplete } from "./patient/selfpay/SignUpComplete";
import { Layout } from "common/AppLayout";
import { ReferralPartnerList } from "./admin/referralPartners/List";
import { EditReferralPartner } from "./admin/referralPartners/Edit";
import { Login } from "./auth/Login";
import { useAppRoutes } from "./useRoutes";
import { UploadEmployees } from "./admin/clients/UploadEmployees";
import { MdoPostHogProvider, usePostHogTracker } from "common/PostHog";
import { Mobile } from "./auth/Mobile";
import { MobileApp } from "./open/MobileApp";

export default function AppRoutes() {
  return (
    <MdoPostHogProvider>
      <BrowserRouter>
        <ToastContainer />
        <ClearAlertsOnRouteChange />
        <LangContainer
          resources={[
            { langCode: "en", translation: en },
            { langCode: "es", translation: es },
          ]}
        >
          <DefaultUnhandledRejectionHandler />
          <UserContainer loadUser={tryLoadUser}>
            <RoutesComponent />
          </UserContainer>
        </LangContainer>
      </BrowserRouter>
    </MdoPostHogProvider>
  );
}

function RoutesComponent() {
  usePostHogTracker();
  return (
    <Routes>
      <Route
        path="/"
        element={<Layout Header={<AppHeader containerType="fluid-container" />} containerClassName="container-fluid" />}
      >
        <Route index element={<Home />} />

        {/* Admin */}
        <Route element={<ProtectedRoute hasAccess={({ hasRole }) => hasRole(RolesEnum.Administrator)} />}>
          <Route path={routes.admin.users.list.routerUrl} element={<UsersList />} />
          <Route path={routes.admin.users.edit.routerUrl} element={<EditUser />} />
          <Route path={routes.admin.users.associatedUsers.edit.routerUrl} element={<EditAssociatedUser />} />

          <Route path={routes.admin.clients.list} element={<ClientsList />} />
          <Route path={routes.admin.clients.edit.url} element={<EditClient />} />
          <Route path={routes.admin.clients.uploadEmployees.url} element={<UploadEmployees />} />

          <Route path={routes.admin.referralPartners.list} element={<ReferralPartnerList />} />
          <Route path={routes.admin.referralPartners.edit.pathName} element={<EditReferralPartner />} />
        </Route>

        {/* Doctors, MDONurses, SiteNurse */}
        <Route
          element={
            <ProtectedRoute
              hasAccess={({ hasRole }) =>
                hasRole(RolesEnum.Administrator) || hasRole(RolesEnum.MDOrthoNurse) || hasRole(RolesEnum.OnsiteNurse)
              }
            />
          }
        >
          <Route path={routes.careGivers.orders} element={<Orders />} />

          <Route path={routes.careGivers.workersComp} element={<WorkersCompList />} />
          <Route path={routes.admin.settings.path} element={<Settings />} />
        </Route>

        {/* Doctors and MDONurses */}
        <Route
          element={
            <ProtectedRoute hasAccess={({ hasRole }) => hasRole(RolesEnum.Doctor) || hasRole(RolesEnum.MDOrthoNurse)} />
          }
        >
          <Route path={routes.careGivers.schedule} element={<Schedule />} />
          <Route path={routes.careGivers.patients} element={<PatientList />} />
          <Route path={routes.careGivers.patientsDetails.path} element={<PatientDetails />} />
          <Route path={routes.careGivers.results} element={<Results />} />
          <Route path={routes.careGivers.scheduleAppointment.path} element={<CgScheduleAppointment />} />
        </Route>

        {/* All logged in users */}
        <Route element={<ProtectedRoute />}>
          <Route path={routes.auth.mobile} element={<Mobile />} />
          <Route path={routes.messages.pathname} element={<Messages />} />
          <Route path={routes.library.ortho} element={<OrthoLibrary />} />
          <Route path={routes.library.physicalTherapy} element={<PhysicalTherapy />} />
        </Route>

        <Route path="playground" element={<Playground />} />
      </Route>

      {/* Patient & OnSite Nurse*/}
      <Route
        path="/"
        element={<Layout Header={<AppHeader containerType="container" />} containerClassName="container" />}
      >
        <Route
          element={
            <ProtectedRoute
              hasAccess={({ hasRole }) =>
                hasRole(RolesEnum.Patient) ||
                hasRole(RolesEnum.OnsiteNurse) ||
                hasRole(RolesEnum.Doctor) ||
                hasRole(RolesEnum.MDOrthoNurse)
              }
            />
          }
        >
          <Route path={routes.patient.dashboard} element={<Dashboard />} />
          <Route path={routes.patient.medicalEmergency} element={<MedicalEmergency />} />
          <Route path={routes.patient.workersCompDisclaimer} element={<WorkersCompDisclaimer />} />
          <Route path={routes.patient.medicalHistory.path} element={<MedicalHistory />} />
          <Route path={routes.patient.selectBodyPart.path} element={<SelectBodyPart />} />
          <Route path={routes.patient.questionnaire.path} element={<Questionnaire />} />
          <Route path={routes.patient.schedule.path} element={<ScheduleAppointment />} />
          <Route path={routes.patient.visitSummary.path} element={<VisitSummary />} />

          <Route path={routes.patient.selfPay.verify} element={<Landing />} />
          <Route path={routes.patient.selfPay.pay.url} element={<Pay />} />
          <Route path={routes.patient.selfPay.paymentSuccess} element={<PaymentSuccess />} />
        </Route>
      </Route>

      {/* Self Pay and anonymous users */}
      <Route
        path="/"
        element={<Layout Header={<AppHeader containerType="container" />} containerClassName="container g-0" />}
      >
        <Route path={routes.selfpay} element={<Landing />} />
        <Route path={routes.patient.selfPay.landing.pathName} element={<Landing />} />
        <Route path={routes.patient.selfPay.signup} element={<SignUp />} />
        <Route path={routes.patient.selfPay.signUpComplete} element={<SignUpComplete />} />
        <Route path={routes.auth.login} element={<Login />} />
        <Route path={routes.open.mobileApp} element={<MobileApp />} />
      </Route>

      <Route path="/" element={<ProtectedRoute />}>
        {/* We want print to be separate to not include header */}
        <Route path={routes.printRoutes.orders.pathname} element={<PrintOrders />} />
        <Route path={routes.careGivers.encounterPrintout.path} element={<PrintEncounter />} />
      </Route>

      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

function ProtectedRoute({ hasAccess }: { hasAccess?: (userHook: UserContextType) => boolean }) {
  const userHook = useUser();
  const [show, setShow] = useState(false);
  const location = useLocation();
  const appRoutes = useAppRoutes();

  useRunOnMount(() => {
    let canAccessThisRoute = false;
    if (hasAccess) {
      canAccessThisRoute = hasAccess(userHook);
    } else {
      canAccessThisRoute = userHook.isLoggedIn();
    }
    if (canAccessThisRoute) {
      setShow(true);
    } else {
      appRoutes.go(routes.auth.loginUrl());
    }
  });

  return (
    <>
      {show ? (
        <>
          <SessionGuard isDebug idleTimeoutInMinutes={30} />
          <Outlet key={location.key} />
        </>
      ) : null}
    </>
  );
}

async function tryLoadUser(): Promise<User> {
  const { data } = await tryGetLoggedInUser();
  return data ? { ...data, fullName: `` } : data;
}
